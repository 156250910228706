var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.priceList)?_c('div',{staticClass:"price-list-detail"},[_c('PriceListDetailHeader',{attrs:{"priceList":_vm.priceList,"isPriceListProposal":_vm.isPriceListProposal,"totItems":_vm.totItems},on:{"updatePriceList":_vm.updatePriceList,"validateProposalRequest":_vm.validateProposalRequest}}),(
      _vm.productPrices &&
        _vm.productPrices.productPrices &&
        _vm.productPrices.productPrices.length > 0
    )?_c('v-list',{key:_vm.refreshKey,staticClass:"product-price-list",attrs:{"flat":""}},[_vm._l((_vm.productPrices.productPrices),function(productPrice){return [_c('PriceListDetailRow',{key:productPrice.refreshItemKey || productPrice.priceId,attrs:{"productPrice":productPrice,"priceListProposalId":_vm.priceList.priceListProposalId,"proposalStatus":_vm.priceList.publicationState
            ? _vm.priceList.publicationState.publicationStateId
            : undefined},on:{"deleteProductPrice":_vm.deleteProductPrice,"updateProductPrice":_vm.updateProductPrice}})]})],2):_vm._e(),(!_vm.loading && _vm.pager && _vm.pager.totPages > 1)?_c('v-pagination',{attrs:{"value":_vm.pageFilter,"length":_vm.pager.totPages,"totalVisible":_vm.$vuetify.breakpoint.xs ? 6 : 7},on:{"next":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePageFilter.apply(null, arguments)},"prev":_vm.handlePageFilter,"input":_vm.handlePageFilter}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }