<template>
  <v-form
    ref="form"
    v-model="formModel"
    @submit.prevent="modifyPriceListProposal"
  >
    <v-card class="add-to-price-list-card">
      <v-card-title>
        Modifica proposta listino
      </v-card-title>

      <v-card-text>
        <!-- BEGIN DATE INIT -->
        <v-menu
          ref="menu"
          v-model="menuFromDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDatePickerModel"
              :label="$t('priceList.insertPriceListProposal.fromDate')"
              :rules="dateRules"
              class="mt-1"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              append-icon="$calendar"
              @click:append="menuFromDate = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="w-100"
            locale="it-IT"
            ref="picker"
            color="primary"
            v-model="selectedDateFromDate"
            :min="
              $dayjs()
                .subtract(1, 'years')
                .format('YYYY-MM-DD')
            "
            first-day-of-week="1"
            type="date"
            no-title
            @input="handleDateChangeFromDate"
          ></v-date-picker>
        </v-menu>
        <!-- BEGIN DATE END -->
        <!-- END DATE MENU INIT -->
        <v-menu
          ref="menu"
          v-model="menuToDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDatePickerModel"
              :label="$t('priceList.insertPriceListProposal.toDate')"
              :rules="dateRules"
              class="mt-1"
              required
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              append-icon="$calendar"
              @click:append="menuToDate = true"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="w-100"
            locale="it-IT"
            ref="picker"
            color="primary"
            v-model="selectedDateToDate"
            :min="
              $dayjs()
                .subtract(1, 'years')
                .format('YYYY-MM-DD')
            "
            first-day-of-week="1"
            type="date"
            no-title
            @input="handleDateChangeToDate"
          ></v-date-picker>
        </v-menu>
        <!-- END MENU END -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined depressed @click="abort">
          {{ $t("common.abort") }}
        </v-btn>
        <v-btn type="submit" depressed color="primary">
          {{ $t("priceList.insertPriceListProposal.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<style lang="scss">
.add-to-price-list-card {
  .v-picker.v-card.v-picker--date {
    width: 100%;
  }
}
</style>
<script>
import priceListService from "~/service/priceListService";
import priceListProposalService from "~/service/priceListProposalService";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "InsertPriceListProposal",
  props: {
    priceListProposal: { type: Object, required: false }
  },
  data() {
    return {
      errorMessage: {},
      formModel: undefined,
      loaded: false,
      warehouseList: [],
      warehouseListModel: undefined,
      priceListList: undefined,
      priceListIdModel: undefined,
      priceListNameModel: undefined,
      menuFromDate: false,
      selectedDateFromDate: this.priceListProposal.priceList.fromDate,
      selectedDateToDate: this.priceListProposal.priceList.toDate,
      menuToDate: false,
      dateRules: [requiredValue("Inserire una data")],
      requiredRules: [requiredValue()]
    };
  },
  computed: {
    fromDatePickerModel: {
      get: function() {
        return this.selectedDateFromDate
          ? this.$dayjs(this.selectedDateFromDate).format("DD-MM-YYYY")
          : "";
      },
      set: function(value) {
        this.selectedDateFromDate = this.$dayjs(value, "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
      }
    },
    toDatePickerModel: {
      get: function() {
        return this.selectedDateToDate
          ? this.$dayjs(this.selectedDateToDate).format("DD-MM-YYYY")
          : "";
      },
      set: function(value) {
        this.selectedDateToDate = this.$dayjs(value, "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        );
      }
    }
  },
  methods: {
    async fetchPriceListList() {
      try {
        const result = await priceListService.getListToClone();
        if (result.response?.status == 0 && result.data?.priceList) {
          this.priceListList = result.data.priceList;
        } else if (result.response) {
          this.errorMessage = result.response;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loaded = true;
      }
    },
    async fetchPriceListProposalWarehouseList() {
      const result = await priceListProposalService.getWarehouseList();
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseList = result.data.warehouses;
      } else {
        this.warehouseList = [];
      }
    },
    async fetchSuggestedPriceListProposalWarehouse(priceListId) {
      const result = await priceListProposalService.getSuggestedWarehouseList(
        priceListId ? priceListId : this.priceListIdModel
      );
      if (
        result &&
        result.data &&
        result.data.warehouses &&
        result.data.warehouses.length > 0
      ) {
        this.warehouseListModel = result.data.warehouses[0].warehouseId;
      } else {
        this.warehouseListModel = undefined;
      }
    },
    async modifyPriceListProposal() {
      console.log(this.priceListProposal);
      if (this.$refs.form.validate()) {
        const modifyResult = await priceListProposalService.modify(
          this.priceListProposal.priceList.priceListId, //idPriceList
          this.priceListProposal.priceListProposalId, //idPriceList
          this.$dayjs(this.fromDatePickerModel, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ), //fromDate
          this.$dayjs(this.toDatePickerModel, "DD-MM-YYYY").format("YYYY-MM-DD") //toDate
        );
        console.log("modifyResult", modifyResult);
        if (modifyResult.response.status === 0) {
          this.$emit("submit", modifyResult.data);
        }
      }
    },
    abort() {
      this.$emit("submit", false);
    },
    handleDateChangeFromDate(date) {
      this.selectedDateFromDate = date;
      this.menuFromDate = false;
    },
    handleDateChangeToDate(date) {
      this.selectedDateToDate = date;
      this.menuToDate = false;
    }
  },
  async mounted() {}
};
</script>
