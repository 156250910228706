var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"price-list-detail-table",attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-row',[_c('v-col',{staticClass:"left-block",attrs:{"cols":"12","lg":"7","md":"12"}},[_c('div',{staticClass:"price-list-detail-table-info price-list-name"},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.priceListName"))+" "),_c('strong',[_vm._v(_vm._s(_vm.isPriceListProposal ? _vm.priceList.priceList.name : _vm.priceList.name))])]),(_vm.priceList.publicationState && _vm.isPriceListProposal)?_c('div',{staticClass:"price-list-detail-table-info price-list-status",class:_vm.priceList.publicationState},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.status"))+" "),_c('PriceListProposalStatus',{attrs:{"publicationState":_vm.priceList.publicationState}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.status"))+" "),_c('PriceListProposalStatus',{attrs:{"status":_vm.$dayjs().isBefore(_vm.$dayjs(_vm.priceList.toDate).add(1, 'day')) ? 1 : 0}})],1),(!_vm.isPriceListProposal)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.id"))+" "+_vm._s(_vm.priceList.priceListId)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"px-0"},[(!_vm.isPriceListProposal)?_c('v-btn',{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":function($event){return _vm.editPriceList()}}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.edit"))+" "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("$edit")])],1):_vm._e(),(
            !_vm.isPriceListProposal &&
              _vm.$dayjs().isBefore(_vm.$dayjs(_vm.priceList.toDate))
          )?_c('v-btn',{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":function($event){return _vm.closePriceList()}}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.close"))+" "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("mdi-lock-outline")])],1):_vm._e(),(
            _vm.isPriceListProposal &&
              _vm.priceList.publicationState.publicationStateId == -1
          )?_c('v-btn',{attrs:{"color":"primary","outlined":"","depressed":""},on:{"click":function($event){return _vm.deletePriceListProposal()}}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.delete"))+" "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("$delete")])],1):_vm._e(),(
            _vm.isPriceListProposal &&
              _vm.priceList.publicationState.publicationStateId == -1
          )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.validateProposalRequest()}}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.approvalRequest"))+" "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("$starfull")])],1):_vm._e(),(
            _vm.isPriceListProposal &&
              _vm.priceList.publicationState.publicationStateId == -1
          )?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.editPriceListProposal()}}},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.editDate"))+" "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("$edit")])],1):_vm._e()],1)],1),_c('v-col',{staticClass:"right-block",attrs:{"cols":"12","lg":"5","md":"12"}},[_c('div',{staticClass:"price-list-detail-table-info price-list-begin-date"},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.beginDate"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$dayjs( _vm.priceList.priceList && _vm.priceList.priceList.fromDate ? _vm.priceList.priceList.fromDate : _vm.priceList.fromDate ).format("DD MMMM YYYY"))+" ")])]),_c('div',{staticClass:"price-list-detail-table-info price-list-end-date"},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.endDate"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$dayjs( _vm.priceList.priceList && _vm.priceList.priceList.toDate ? _vm.priceList.priceList.toDate : _vm.priceList.toDate ).format("DD MMMM YYYY"))+" ")])]),_c('div',{staticClass:"price-list-detail-table-info price-list-end-date"},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.warehouse"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.priceList ? _vm.priceList.priceListProposal?.warehouse?.name || _vm.priceList.warehouse?.name || "" : "")+" ")])]),_c('div',{staticClass:"price-list-detail-table-info price-list-end-date"},[_vm._v(" "+_vm._s(_vm.$t("priceList.priceListDetail.header.items"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.totItems)+" ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }